@import "./src/style/scss/colors";

.button {

  &-disabled {
    background-color: $disabledBtnColor !important;
    border-color: $disabledBtnColor !important;
    color: white !important;

    &:hover {
      cursor: not-allowed !important;
      opacity: 1 !important;
    }
  }
}
