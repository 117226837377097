@import "./src/style/scss/colors";
@import "./src/style/scss/text";

.input {

  &-label {
    opacity: 0.5;
    line-height: 30px;
  }

  &-field {

  }

  &-error {
    padding-top: 5px;
    font-size: 12px;
    color: $inputErrorText;
  }
}
