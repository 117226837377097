@import "colors";

body {
  margin: 0;
  // FIXME 1: use fonts from design
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $mainTextBlack;
}

h1, h2, h3, h4, p, ul, li {
  margin: 0;
  padding: 0;
}
