@import "./src/style/scss/colors";
@import "./src/style/scss/media";

@mixin mainText() {
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
}

@mixin mainTextMiddle() {
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;

  @include mobile() {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin smallText() {
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
}

@mixin pageTitle() {
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  font-family: "Bauhaus-regular", sans-serif;
  color: $titleBlack;

  @include mobile() {
    font-size: 26px;
    line-height: 30px;
  }
}

@mixin subtitle() {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
}

@mixin dashboardTitle() {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

@mixin mobileNavigationLink() {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}

@mixin dashboardFooterLinks() {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(153, 153, 153, 1);
}

@mixin tableHeader() {
  font-family: 'PT Sans Bold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 1px;
}

@mixin statusText() {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 1px;
}

// new text
@mixin sfDisplayHuge() {
  font-family: 'SF Compact Bold', sans-serif;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.84px;
  text-transform: uppercase;
}

@mixin sfDisplayTitle() {
  font-family: "SF Compact Bold", sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: uppercase;
}

@mixin sfTextNormal() {
  font-family: 'SF Compact Text Medium', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
}

@mixin sfTextContent() {
  @include sfTextNormal();
  font-size: 14px;
  line-height: 16px;
  color: #191925;
}

@mixin sfTextBig() {
  font-family: "SF Compact Bold", sans-serif;
  color: #191925;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

// Rewrite
@mixin tableTitle() {
  @include sfDisplayTitle;
}

@mixin dashboardNavigation() {
  @include sfTextBig;
}

@mixin tableActions() {
  @include sfTextNormal;
  font-size: 16px;
  line-height: 16px;
}

@mixin tableItems() {
  @include sfTextNormal;
  font-size: 12px;
  line-height: 16px;
}
