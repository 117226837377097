@mixin pk {
  @media (max-width: 1280px) { @content; }
}

@mixin notebook {
  @media (max-width: 1024px) { @content; }
}

@mixin mobile {
  @media (max-width: 768px) { @content; }
}

@mixin mobile_landing_big {
  @media (max-width: 868px) { @content; }
}

@mixin mobile_landing_sm {
  @media (max-width: 470px) { @content; }
}