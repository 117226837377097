@import "src/style/scss/effects";
@import "src/style/scss/colors";
@import 'src/style/scss/text';

.login-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  position: relative;
  width: 328px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(199, 207, 210, 0.20);
  background: #ffff;
  padding: 24px;

  &-title {
    color: $secondBlack;
    line-height: 64px;
    @include sfDisplayTitle;
    margin-bottom: 24px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-input:last-child {
      margin-top: 16px;
    }
  }

  &-error {
    padding-top: 10px;
    bottom: 75px;
    position: absolute;
    @include sfTextNormal;
    text-transform: capitalize;
  }

  &-button {
    margin-top: 24px;
    height: 48px;
    width: 100%;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: $white;
    background: $loginBtnColor;
    transition: .5s;
    @include sfTextNormal();
    text-transform: uppercase;
    @include onHover();
  }
}

@include mobile_landing_sm() {
  .login-form {
    margin: auto 16px;
  }
}
