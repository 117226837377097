@import "../fonts/gothic/CenturyGothicRegular.ttf";
@import "../fonts/bauhaus/Bauhaus-Medium.ttf";

@font-face {
  font-family: "Gothic-regular";
  src: local("Gothic"),
  url("../fonts/gothic/CenturyGothicRegular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Bauhaus-regular";
  src: local("Bauhaus"),
  url("../fonts/bauhaus/Bauhaus-Medium.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans";
  src: local("PT"),
  url("../fonts/ptSans/PTSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans Bold";
  src: local("PT-bold"),
  url("../fonts/ptSans/PTSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Century Bold";
  src: local("Century-bold"),
  url("../fonts/century/centurygothic_bold.ttf") format("truetype");
  font-weight: 700;
}

// Compact Display
@font-face {
  font-family: "SF Compact Bold";
  src: local("SF-Compact-Bold"),
  url("../fonts/sfCompact/SF-Compact-Display-Bold.otf") format("truetype");
}


// Compact Text
@font-face {
  font-family: "SF Compact Medium";
  src: local("SF-Compact-Text-Medium"),
  url("../fonts/sfCompact/SF-Compact-Text-Regular.otf") format("truetype");
}