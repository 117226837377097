@import "./colors";
@import "./media";

@mixin onHover() {
  cursor: pointer;
  opacity: .7;
  transition: .5s;

  @include mobile() {
    opacity: 1;
    transition: 0s;
  }
}

@mixin onGreenBtnHover() {
  @include onHover();
  background-color: $loginBtnColor;
  color: white;

  @include mobile() {
    background-color: white;
    color: $loginBtnColor;
  }
}

@mixin boxShadow() {
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.09);
}

@mixin boxShadowDashboard() {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
}

@mixin boxShadowBottom() {
  box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.09);
}

@mixin boxShadowRight() {
  box-shadow: 0 0 20px rgba(199, 207, 210, 20%);
}
